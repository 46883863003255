/* eslint-disable jsx-a11y/alt-text */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultImage from "../../assets/images/Squiggle_S_Square_Green.png";

import Loader from "../../components/Loader";
import { showSnackbar } from "../../slice/snackbarSlice";
import { genrateCalendlyUserID, getManageProfileUser } from "../../thunks/auth";
import { uploadFile } from "../../thunks/documents";
import { updateMe } from "../../thunks/user";
import {
  IMG_BASE_URL,
  NOTIFICATION_MESSAGE,
  USER_ROLE,
  VALIDATION_MSG,
} from "../../utils/constant";

import PhoneInput from "react-phone-number-input";
import RichTextEditor from "../../components/RichTextEditor";
import { OutlinedGreenButton } from "../../components/buttons";
import { updateEmailSignature } from "../../slice/auth";
import { getEmailSignHtml } from "../../utils/util";
import JoditEditotRichText from "../../components/JoditEditotRichText";

const ManageProfile = () => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const lookups = useSelector((state) => state?.lookupData);
  let { Gender: gender, Country: country } = lookups || {};

  const [userData, setUserData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    role: "",
    rolesLookupName: "",
    rolesLookupId: "",
    dob: "",
    phoneNumber: "",
    email: "",
    password: "",
    address: {
      street: "",
      city: "",
      state: "",
      country: "",
    },
    profilePhotoUrl: "",
    type: "",
    IntroducersOrCounsultantsUser: [],
    TaskUser: [],
    isVatVerified: "",
    vatNumber: null,
    emailAppPassword: "",
  });
  const [schedulingUrl, setSchedulingUrl] = useState(null);

  // const signRef = useRef(null);
  const [signData, setSignData] = useState(""); // state for Email-Sign

  const [userImage, setUserImage] = useState(null);
  const [isError, setError] = useState(false);
  const [isLoader, setLoader] = useState(true);
  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const [tempProfilePhotoUrl, setTempProfilePhotoUrl] = useState("");
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  async function getDefaultEmailSignTemplate() {
    return await getEmailSignHtml({
      userDetails: {
        ...userDetails,
        role:
          userDetails?.role === USER_ROLE?.OFFICE_ADMIN
            ? "Legal Administrator"
            : userDetails?.role,
      },
    });
  }

  const [emailSignTemplate, setEmailSignTemplate] = useState("");
  // -----------------------------------IMAGE OPERATION-------------------
  const handleLocalImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    setSelectedUserProfile(selectedFile);
    if (selectedFile) {
      const blobUrl = URL.createObjectURL(selectedFile);
      setUserData({ ...userData, profilePhotoUrl: blobUrl });
      //!SECTION
      handleChangeFormData(blobUrl, "profilePhotoUrl");
    }
  };
  // ---------------------------------- UPLOAD IMAGE API CALLL - ---------------
  const handleUploadImage = async () => {
    let uploadFileResponse = "";

    let formData = new FormData();
    formData.append("myfile", selectedUserProfile, selectedUserProfile?.name);

    const imagePayload = {
      formData,
      url: "/file/upload",
    };

    try {
      uploadFileResponse = await dispatch(uploadFile(imagePayload)).unwrap();
    } catch (error) {}

    return uploadFileResponse;
  };

  const handleSave = async () => {
    if (!userData?.firstName || !userData?.lastName || !userData?.phoneNumber) {
      setError(true);
      return;
    } else {
      setError(false);
      try {
        const { IntroducersOrCounsultantsUser, ...newUserData } = userData;
        // let emailHtmlStr = signRef.current.getContent();
        // let data = { ...newUserData, emailSignature: emailHtmlStr || "" };
        let data = { ...newUserData, emailSignature: signData || "" };
        setLoader(true);
        let formPayload;
        let uploadFileResponse = "";
        if (selectedUserProfile) {
          uploadFileResponse = await handleUploadImage();
          formPayload = {
            ...data,
            profilePhotoUrl: uploadFileResponse,
          };
        } else {
          formPayload = {
            ...data,
            profilePhotoUrl: tempProfilePhotoUrl || "",
          };
        }

        setLoader(true);
        const res = await dispatch(updateMe(formPayload)).unwrap();
        if (res) {
          dispatch(updateEmailSignature(signData));
          dispatch(
            showSnackbar({
              show: true,
              message: NOTIFICATION_MESSAGE?.DATA_SAVE_SUCCESS,
              severity: "success",
            })
          );
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        dispatch(
          showSnackbar({
            message: error?.message,
            severity: "error",
          })
        );
      } finally {
        setLoader(false);
      }
    }
  };

  const getUserDetails = async () => {
    try {
      const response = await dispatch(getManageProfileUser()).unwrap();

      if (response) {
        setUserData({
          ...userData,
          firstName: response?.firstName || "",
          middleName: response?.middleName || "",
          lastName: response?.lastName || "",
          gender: response?.gender || "",
          role: response?.role || "",
          rolesLookupName: response?.rolesLookupName || "",
          rolesLookupId: response?.rolesLookupId || "",
          dob: new Date(response?.dob || null),
          phoneNumber: response?.phoneNumber || "",
          email: response?.email || "",
          address: {
            street: response?.address?.street || "",
            city: response?.address?.city || "",
            state: response?.address?.state || "",
            country: response?.address?.country || "",
          },
          type: response?.type || "",
          emailSignature: response?.emailSignature || "",
          IntroducersOrCounsultantsUser:
            response?.IntroducersOrCounsultantsUser || [],
          TaskUser: response?.TaskUser || [],
          isVatVerified: response?.isVatVerified === true ? "Yes" : "No",
          profilePhotoUrl:
            response?.type === "Internal"
              ? response?.profilePhotoUrl
              : response?.profilePhotoUrl
              ? `${process.env.REACT_APP_API_PATH}${IMG_BASE_URL}${response?.profilePhotoUrl}`
              : "",
          vatNumber: response?.vatNumber || null,
          emailAppPassword: response?.emailAppPassword || null,
        });
        setSchedulingUrl(response?.scheduling_url);
        setTempProfilePhotoUrl(response?.profilePhotoUrl || "");
        setEmailSignTemplate(response?.emailSignature || "");
        setLoader(false);
      }
    } catch (error) {}
  };

  const handleChangeFormData = (value, key) => {
    if (!key.includes("address")) {
      setUserData({ ...userData, [key]: value });
    } else {
      let tempKey = key.split(".")[1];
      setUserData({
        ...userData,
        address: { ...userData.address, [tempKey]: value },
      });
    }
  };

  const generateCalendlyId = async () => {
    try {
      setLoader(true);
      const response = await dispatch(genrateCalendlyUserID()).unwrap();
      if (response) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.CALENDLY_ID_GENERATE_SUCCESS,
            severity: "success",
          })
        );
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: error?.message,
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      {isLoader && <Loader />}
      <Grid
        border={0}
        padding={3}
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
      >
        <Grid className="serif_display_regular_26" container xs={12}>
          <Grid xs={9}> My Account</Grid>
          {!schedulingUrl && (
            <Grid sx={{ width: "30%" }} xs={3}>
              <Button
                size="small"
                component="label"
                variant="contained"
                style={{
                  boxShadow: "none",
                  backgroundColor: "#00ce3f",
                  borderRadius: "20px",
                }}
                className="normal_normal_semibold_14_Manrope"
                onClick={generateCalendlyId}
              >
                Update Calendly Info
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid
          display={"flex"}
          flexDirection={"row"}
          gap={4}
          mt={4}
          height={"100%"}
        >
          {/* USER IMAGE */}
          {/* --------------------------------------------------------------------  */}
          <Avatar
            sx={{
              height: "180px",
              width: "180px",
              border: `4px solid #00CE3F`,
              cursor: "pointer",
            }}
            onClick={() => inputRef.current.click()}
          >
            <img
              src={userData?.profilePhotoUrl || DefaultImage}
              alt="Squiggle"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: "50%",
              }}
            />

            <input
              type="file"
              ref={inputRef}
              src={userImage}
              style={{ display: "none" }}
              onChange={handleLocalImageUpload}
            />
          </Avatar>
          {/* -------------------------------------------------------------------------  */}
          <Grid
            item
            xs={10}
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            height={"100%"}
          >
            <Grid
              item
              xs={10}
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              gap={6}
            >
              <Grid display={"flex"} alignItems={"flex-start"} gap={3}>
                <Grid sx={{ width: "20%" }}>
                  <TextField
                    disabled={userData?.type === "Internal"}
                    value={userData?.firstName}
                    error={isError && !userData?.firstName}
                    helperText={
                      isError && !userData?.firstName && VALIDATION_MSG.REQUIRED
                    }
                    required
                    fullWidth
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="First Name"
                    placeholder="First Name"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      handleChangeFormData(e?.target?.value, "firstName")
                    }
                  />
                </Grid>
                <Grid sx={{ width: "20%" }}>
                  <TextField
                    disabled={userData?.type === "Internal"}
                    value={userData?.middleName}
                    fullWidth
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="Middle Name"
                    placeholder="Middle Name"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      handleChangeFormData(e?.target?.value, "middleName")
                    }
                  />
                </Grid>
                <Grid sx={{ width: "20%" }}>
                  <TextField
                    disabled={userData?.type === "Internal"}
                    value={userData?.lastName}
                    error={isError && !userData?.lastName}
                    helperText={
                      isError && !userData?.lastName && VALIDATION_MSG.REQUIRED
                    }
                    required
                    fullWidth
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="Last Name"
                    placeholder="Last Name"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      handleChangeFormData(e?.target?.value, "lastName")
                    }
                  />
                </Grid>
              </Grid>
              <Grid display={"flex"} alignItems={"flex-start"} gap={3}>
                <Grid sx={{ width: "20%" }}>
                  <Grid item xs={6}>
                    <FormControl>
                      <FormLabel
                        style={{ color: "#00CE3F", fontSize: "11px" }}
                        className=""
                      >
                        Gender
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="Male"
                        name="radio-buttons-group"
                        onChange={(e) =>
                          handleChangeFormData(e?.target?.value, "gender")
                        }
                      >
                        {gender?.length > 0 &&
                          gender?.map((d) => (
                            <FormControlLabel
                              value={userData?.gender}
                              control={
                                <Radio
                                  checked={userData?.gender === d?.name}
                                  size="small"
                                  sx={{
                                    "&, &.Mui-checked": {
                                      color: "#00CE3F",
                                    },
                                    marginTop: 0,
                                  }}
                                  value={d?.name}
                                />
                              }
                              label={d?.name}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid sx={{ width: "15%" }}>
                  <Grid>
                    <Typography
                      className=""
                      sx={{ fontSize: "10px", color: "#00CE3F" }}
                    >
                      Role
                    </Typography>
                  </Grid>

                  <Grid>
                    <Typography className="normal_normal_16_Manrope">
                      {userData?.rolesLookupName || "NA"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid sx={{ width: "20%" }}>
                  <Typography className="normal_normal_600_10_Manropee">
                    VAT Verified
                  </Typography>
                  <Typography>{userData?.isVatVerified}</Typography>
                </Grid>

                {userData?.isVatVerified && (
                  <Grid sx={{ width: "20%" }}>
                    <Typography className="normal_normal_600_10_Manropee">
                      VAT Number
                    </Typography>
                    <Typography>GB {userData?.vatNumber || "NA"}</Typography>
                  </Grid>
                )}
              </Grid>

              <Grid display={"flex"} alignItems={"flex-start"} gap={3}>
                <Grid sx={{ width: "20%" }}>
                  <label
                    style={{ position: "relative", bottom: 5 }}
                    className="normal_normal_600_10_Manropee"
                  >
                    Work Number
                  </label>
                  <PhoneInput
                    country="UK"
                    defaultCountry="GB"
                    placeholder="Work Number"
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    value={userData?.phoneNumber}
                    onChange={(e) => handleChangeFormData(e, "phoneNumber")}
                  />
                  {isError && !userData?.phoneNumber && (
                    <FormHelperText className="red">
                      {VALIDATION_MSG.REQUIRED}
                    </FormHelperText>
                  )}
                  {/* <TextField
                    value={userData?.phoneNumber}
                    error={isError && !userData?.phoneNumber}
                    helperText={
                      isError &&
                      !userData?.phoneNumber &&
                      VALIDATION_MSG.REQUIRED
                    }
                    fullWidth
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="Mobile Number"
                    placeholder="Mobile Number"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      handleChangeFormData(e?.target?.value, "phoneNumber")
                    }
                  /> */}
                </Grid>
                <Grid sx={{ width: "20%" }}>
                  <TextField
                    inputProps={{ readOnly: true, autoComplete: "off" }}
                    value={userData?.email}
                    error={isError && !userData?.email}
                    helperText={
                      isError && !userData?.email && VALIDATION_MSG.REQUIRED
                    }
                    required
                    fullWidth
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="Email"
                    placeholder="Email"
                    variant="standard"
                    InputLabelProps={{ shrink: true, autoComplete: "off" }}
                    onChange={(e) =>
                      handleChangeFormData(e?.target?.value, "email")
                    }
                  />
                </Grid>
                {/* <Grid sx={{ width: "20%" }}>
                  <TextField
                    type="password"
                    value={userData?.emailAppPassword || null}
                    fullWidth
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="Email App Password"
                    placeholder="Enter Email Password"
                    variant="standard"
                    InputProps={{
                      autoComplete: "new-password",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      handleChangeFormData(e?.target?.value, "emailAppPassword")
                    }
                  />
                </Grid> */}
              </Grid>
              <Grid display={"flex"} alignItems={"flex-start"} gap={3}>
                <Grid sx={{ width: "20%" }}>
                  <TextField
                    value={userData?.address?.street}
                    fullWidth
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="Street"
                    placeholder="Street"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      handleChangeFormData(e?.target?.value, "address.street");
                    }}
                  />
                </Grid>
                <Grid sx={{ width: "20%" }}>
                  <TextField
                    value={userData?.address?.city}
                    fullWidth
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="City"
                    placeholder="City"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      handleChangeFormData(e?.target?.value, "address.city")
                    }
                  />
                </Grid>
                <Grid sx={{ width: "20%" }}>
                  <TextField
                    value={userData?.address?.state}
                    fullWidth
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="County"
                    placeholder="County"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      handleChangeFormData(e?.target?.value, "address.state")
                    }
                  />
                </Grid>
                <Grid sx={{ width: "20%" }}>
                  <FormControl fullWidth>
                    <InputLabel
                      className="normal_normal_600_12_Manropee"
                      shrink={true}
                      style={{ lineHeight: "2.0375", marginLeft: "-12px" }}
                    >
                      Country
                    </InputLabel>
                    <Select
                      className="formControlField"
                      value={userData?.address?.country}
                      label="Country"
                      onChange={(e) =>
                        handleChangeFormData(
                          e?.target?.value,
                          "address.country"
                        )
                      }
                      variant="standard"
                      size="small"
                    >
                      {country?.length > 0 &&
                        country?.map((d) => (
                          <MenuItem key={d?.id} value={d?.name}>
                            {d?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {/* <TextField
                    value={userData?.address?.country}
                    fullWidth
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="Country"
                    placeholder="Country"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      handleChangeFormData(e?.target?.value, "address.country")
                    }
                  /> */}
                </Grid>
              </Grid>
              {/* INTRODUCER'S ONLY FOR CONSULTANTS LOGIN */}
              {userData?.role === USER_ROLE?.CONSULTANT ? (
                <Grid item xs={12}>
                  <Typography
                    className=""
                    sx={{ fontSize: "10px", color: "#00CE3F" }}
                  >
                    Introducers
                  </Typography>
                  {userData?.IntroducersOrCounsultantsUser?.length > 0 &&
                    userData?.IntroducersOrCounsultantsUser?.map((d) => (
                      <Chip
                        label={`${d?.firstName} ${d?.lastName}`}
                        style={{ margin: "0.5rem" }}
                      />
                    ))}
                </Grid>
              ) : null}

              {/* CONSULTANTS ONLY FOR INTRODUCERS LOGIN */}
              {userData?.role === USER_ROLE?.INTRODUCER ? (
                <Grid width={"100%"}>
                  <Typography
                    className=""
                    sx={{ fontSize: "10px", color: "#00CE3F" }}
                  >
                    Consultants
                  </Typography>
                  {userData?.IntroducersOrCounsultantsUser?.length > 0 &&
                    userData?.IntroducersOrCounsultantsUser?.map((d) => (
                      <Chip
                        label={`${d?.firstName} ${d?.lastName}`}
                        style={{ margin: "0.5rem" }}
                      />
                    ))}
                </Grid>
              ) : null}

              {/* EMAIL SIGNATURE IS VISIBLE TO ALL ROLE'S EXPECT INTRODUCER   */}
              {userData?.role !== USER_ROLE?.INTRODUCER && (
                <Grid item xs={12}>
                  <Accordion fullWidth className="dashboard__accordion">
                    <AccordionSummary
                      style={{ marginRight: "60px" }}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography className="serif_display_regular_18 dark_grey">
                        Email Signature
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display="flex" alignItems="center" mb={2}>
                        <Typography className="normal_normal_600_12_Manrope green mr-2">
                          Email Signature
                        </Typography>
                        <Box pl={2}>
                          <OutlinedGreenButton
                            label={"Default Template"}
                            onClick={async () => {
                              let newT = await getDefaultEmailSignTemplate();
                              setEmailSignTemplate(newT);
                            }}
                          />
                        </Box>
                      </Box>
                      {/* <RichTextEditor
                        initialValue={emailSignTemplate}
                        ref={signRef}
                        height={320}
                      /> */}
                      <JoditEditotRichText
                        value={emailSignTemplate || ""}
                        height={200}
                        onChange={(value) => setSignData(value)}
                        // ref={signRef}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} mt={4}>
              {/* only when role is Office Admin and there are Task Users */}
              {userData?.role === USER_ROLE?.OFFICE_ADMIN &&
                userData?.TaskUser?.length > 0 && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography className="normal_normal_600_12_Manrope green">
                        Tasks
                      </Typography>
                    </Grid>
                    {userData.TaskUser.map((item) => (
                      <Grid item key={item.id}>
                        <Chip label={item?.name} style={{ margin: "0.5rem" }} />
                      </Grid>
                    ))}
                  </Grid>
                )}
            </Grid>

            <Grid
              style={{
                marginTop: "auto",
              }}
            >
              <Grid
                item
                xs={11}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  zIndex: 2,
                }}
                style={{
                  position: "fixed",
                  bottom: 5,
                  left: 0,
                  right: 10,
                }}
              >
                <Button className="save_button" onClick={handleSave}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ManageProfile;
