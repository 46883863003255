import { CopyAll } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import LanguageIcon from "@mui/icons-material/Language";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import JoditEditotRichText from "../../../components/JoditEditotRichText";
import Loader from "../../../components/Loader";
import { AutoCompleteField } from "../../../components/inputs";
import {
  addEmailData,
  addEmailPortalData,
  clearEmailDocuments,
  removeEmailDocument,
  updateEmailFormData,
} from "../../../slice/documents";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { getManageProfileUser } from "../../../thunks/auth";
import { getDocumentTypeById, uploadFile } from "../../../thunks/documents";
import { getEmailTemplates } from "../../../thunks/email_template";
import { sendMailThunk } from "../../../thunks/instruction";
import {
  emailTemplateLookupName,
  INBOUND_PARSE_URL,
  MAX_EMAIL_FILE_SIZE,
  NOTIFICATION_MESSAGE,
  USER_ROLE,
  VALIDATION_MSG,
} from "../../../utils/constant";
import ViewPortalDocuments from "./ViewPortalDocuments";
import { useLocation } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const EmailBox = ({
  open,
  onClose,
  userDetails,
  instructionData,
  instructionId,
  emailConfigData,
  clientData,
}) => {
  // console.log("user :: ", userDetails);
  const location = useLocation();
  const attatchments = useSelector((state) => state?.document?.emailData);
  const lookups = useSelector((state) => state?.lookupData);
  let { "Document Type": documentType } = lookups || {};
  const Client1 =
    instructionData?.milestone === "Prospect"
      ? instructionData?.InstructionClients[0]
      : instructionData?.instructionAddresBookClients[0];
  const Client2 =
    instructionData?.milestone === "Prospect"
      ? instructionData?.InstructionClients[1]
      : instructionData?.instructionAddresBookClients[1];
  const dispatch = useDispatch();
  const [attachments, setAttachments] = useState([]);
  const [isPortal, setIsPortal] = useState(false);
  const [showBcc, toggleBcc] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [consultantEmail, setConsultantEmail] = useState([
    {
      id: instructionData?.ownerOfInstruction?.id,
      label: instructionData?.ownerOfInstruction?.email,
    },
  ]);
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [emailBody, setEmailBody] = useState(userDetails?.emailSignature || "");
  const [isUploading, setUploading] = useState(false);
  const [progress, setProgress] = useState("0/0");
  const [emailConfig, setEmailConfig] = useState(emailConfigData || {});
  const [sharedEmail, setSharedEmail] = useState([]);
  const [emailData, setEmailData] = useState({
    subject: "",
    to:
      Array.isArray(emailConfig?.to) && emailConfig?.to?.length >= 1
        ? emailConfig?.to?.length === 1
          ? emailConfig?.to
          : emailConfig?.to[0]?.label === emailConfig?.to[1]?.label
          ? [emailConfig?.to[0]]
          : emailConfig?.to
        : [],
    template: "",
    from: emailConfig?.from || "",
    cc: [],
    bcc: [],
    html: userDetails?.emailSignature || "",
    attachments: [],
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const columns = [
    {
      id: "clientName",
      label: (
        <Typography className="table_cell_heading">Client Name</Typography>
      ),
      minWidth: 200,
    },
    {
      id: "documentTypeId",
      label: (
        <Typography className="table_cell_heading">Document Type</Typography>
      ),
      minWidth: 100,
    },
    {
      id: "documentSubTypeId",
      label: (
        <Typography className="table_cell_heading">Document SubType</Typography>
      ),
      minWidth: 150,
    },
    {
      id: "fileName",
      label: <Typography className="table_cell_heading">File name</Typography>,
      maxWidth: 100,
    },
    {
      id: "action",
      label: <Typography className="table_cell_heading">Action</Typography>,
      maxWidth: 100,
    },
  ];
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /* Get email templates on component load */
  const getInitialData = async () => {
    try {
      setLoading(true);
      const response = await dispatch(
        getEmailTemplates({
          isActive: true,
          roles: userDetails?.role,
          templateLookupName: location?.pathname?.includes(
            "/app/instructions/dashboard/"
          )
            ? emailTemplateLookupName.case
            : emailTemplateLookupName.client,
        })
      ).unwrap();
      if (response) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        showSnackbar({
          message: "Failed to fetch email templates",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  // Method to handle email submit
  const handleSubmitEmail = async () => {
    const totalSize = attatchments?.reduce((acc, file) => {
      if (file?.fileName && file?.fileName?.size) {
        return acc + file.fileName.size;
      }
      return acc;
    }, 0);
    // Validate data before sending email if clientName, clientId, documentTypeId, documentSubTypeId is null
    const validateData = attatchments?.some(
      (document) =>
        (!document?.id && document?.clientName === null) ||
        (!document?.id && document?.clientId === null) ||
        (!document?.id && document?.documentTypeId === null) ||
        (!document?.id && document?.documentSubTypeId === null)
    );
    // Check if to field is empty or subject is empty
    if (emailData?.to?.length <= 0 || !emailData?.subject) {
      setIsError(true);
    }
    // Check if total size of attachments is greater than 20MB
    else if (totalSize > MAX_EMAIL_FILE_SIZE) {
      dispatch(
        showSnackbar({
          message: NOTIFICATION_MESSAGE.EMAIL_FILE_SIZE,
          severity: "error",
        })
      );
    } else if (validateData) {
      dispatch(
        showSnackbar({
          show: true,
          message: "Required client name or document type or document subType.",
          severity: "error",
        })
      );
    } else {
      const emailDocuments = [];
      // Create method to filter out attachments those don't have id key
      const DocumentsWithoutId = attatchments?.filter((d) => !d?.id);
      const DocumentsWithId = attatchments?.filter((d) => d?.id);
      let completed = 0;
      const total = DocumentsWithoutId?.length;
      if (DocumentsWithoutId?.length > 0) {
        for (let index = 0; index < DocumentsWithoutId?.length; index++) {
          const data = DocumentsWithoutId[index];
          let formData = new FormData();
          formData.append("myfile", data?.fileName, data?.fileName?.name);
          // Serialize the clientBody object to JSON
          const clientBodyData = {
            clientName: data?.clientName,
            clientId: data?.clientId,
            documentTypeId: data?.documentTypeId,
            subDocumentTypeId: data?.documentSubTypeId,
            index: index,
          };
          formData.append("clientBody", JSON.stringify(clientBodyData));
          const payload = {
            formData,
            url: `/instruction/${instructionId}/uploadInstructionClientDocuments`,
          };
          try {
            let uploadFileResponse = "";
            setUploading(true);
            uploadFileResponse = await dispatch(uploadFile(payload)).unwrap();
            if (uploadFileResponse) {
              completed += 1;
              setProgress(`${completed}/${total}`);
              emailDocuments.push({
                fileInfo: {
                  ...uploadFileResponse,
                  instructionId: instructionId,
                },
                forClient: data?.clientName,
                documentTypeId: data?.documentTypeId,
                subDocumentTypeId: data?.documentSubTypeId,
                clientId:
                  data?.clientName === "Both" ? undefined : data?.clientId,
              });
              setUploading(false);
            } else {
              setUploading(false);
            }
          } catch (error) {
            setUploading(false);
            dispatch(
              showSnackbar({
                show: true,
                message: `${error?.message}`,
                severity: "error",
              })
            );
          }
        }
      }
      // Create functionality to merge both arrays
      const finalDocuments = [...DocumentsWithId, ...emailDocuments];
      // Call send email method after successfull upload
      await sendEmail(finalDocuments);
    }
  };

  function checkForDuplicateEmails() {
    let to = emailData?.to?.map((item) => item.label) || [];
    let cc = emailData?.cc?.map((item) => item?.label) || [];

    const tempBCC = [
      ...emailData?.bcc?.map((item) => item?.label),
      emailBccLink,
    ];
    let bcc = tempBCC || [];

    // all email arrays into one
    const allEmails = [...to, ...cc, ...bcc];

    // Set to track unique emails
    const uniqueEmails = new Set();

    // Checking for duplicates by iterating through all emails
    for (const email of allEmails) {
      if (uniqueEmails.has(email)) {
        // Duplicate found
        return true;
      }
      uniqueEmails.add(email);
    }

    // No duplicates found
    return false;
  }

  // Method to send email
  const sendEmail = async (finalDocuments) => {
    setLoading(true);
    const tempBCC = [
      ...emailData?.bcc?.map((item) => item?.label),
      emailBccLink,
    ];
    try {
      const payload = {
        subject: emailData?.subject || null,
        to: emailData?.to?.map((item) => item.label) || [],
        cc: emailData?.cc?.map((item) => item?.label) || [],
        bcc: tempBCC || [],
        from: emailData?.from || null,
        html: emailData?.html || null,
        attachments: finalDocuments || [],
        instructionId: instructionId || null,
      };
      let emailResp = await dispatch(sendMailThunk(payload)).unwrap();
      if (emailResp) {
        setLoading(false);
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.EMAIL_SENT_SUCCESS,
            severity: "success",
          })
        );
        handleCloseDialog();
      } else {
        setLoading(false);
        dispatch(
          showSnackbar({
            message: "Failed to send email",
            severity: "error",
          })
        );
      }
    } catch (error) {
      setLoading(false);
      handleCloseDialog();
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  const onChangeAutoComplete = (key, value) => {
    setEmailData({ ...emailData, [key]: value });
  };

  // this link is setup with sendgrid, which will perform inbound parse, so that email data can be saved to backend
  const emailBccLink = `instruction+${instructionId}${INBOUND_PARSE_URL}`;

  const copyBccLink = () => {
    navigator.clipboard
      .writeText(emailBccLink)
      .then(() => {
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.LINK_COPY_SUCCESS,
            severity: "success",
          })
        );
      })
      .catch((err) => {
        console.error("Unable to copy text to clipboard", err);
      });
  };
  // ___________email templates handling _______________

  const emailTemplates = useSelector((state) => {
    let template = state?.emailTemplate?.emailTemplates;
    if (template?.length) {
      template = template?.map((obj) => ({
        ...obj,
        label: obj?.name,
        value: obj?.name,
      }));
    }
    return template;
  });

  // let activeTemplate = watch("activeTemplate");

  /* Method to handle change email template list and set client1, client2 conditionally */
  const handleChangeEmailTemplate = (e) => {
    // Method to get email template data by passing template id
    let activeTemplate = emailTemplates?.find((obj) => obj?.id === e);
    // variables used in template
    let client_1 = `${Client1?.title || ""} ${Client1?.firstName || ""} ${
      Client1?.middleName || ""
    } ${Client1?.lastName || ""} `;
    let client_2 = Client2
      ? ` ${Client2?.title || ""} ${Client2?.firstName || ""} ${
          Client2.middleName || ""
        } ${Client2?.lastName || ""} `
      : "";
    //IF LAST NAME IS SAME
    let full_name =
      Client1?.lastName === Client2?.lastName
        ? `${Client1?.title || ""} & ${Client2?.title || ""} ${
            Client1?.lastName || ""
          }`
        : "";
    let template_replace_values = {
      // client_1,
      // client_2,
      // client_1_firstname,
      // client_2_firstname,
      signature: userDetails?.emailSignature,
      _date: format(new Date(), "dd/MM/yyyy"),
      clients:
        Client1?.lastName === Client2?.lastName &&
        Client1?.title &&
        Client2?.title
          ? full_name
          : `${client_1?.replace(/\s+/g, " ").trim()}` +
            (Client2 ? ` & ${client_2?.replace(/\s+/g, " ").trim()}` : ""),
    };
    // template_replace_values.both_clients =
    //   `${client_1}` + (Client2 ? ` & ${client_2}` : "");
    // template_replace_values.both_clients_firstname =
    //   `${client_1_firstname}` + (Client2 ? ` & ${client_2_firstname}` : "");

    let htmlStr = activeTemplate?.html
      ? activeTemplate?.html
      : userDetails?.emailSignature;
    let subjectStr = activeTemplate?.subject;
    if (htmlStr) {
      function replaceString(originalString, strToReplace, newStr) {
        // Replaces variables in the templates with values
        const pattern = new RegExp(`\\{\\$${strToReplace}\\}`, "g");
        return originalString.replace(pattern, newStr || "");
      }
      for (const key in template_replace_values) {
        htmlStr = replaceString(htmlStr, key, template_replace_values[key]);
        subjectStr &&
          (subjectStr = replaceString(
            subjectStr,
            key,
            template_replace_values[key]
          ));
      }
    }
    htmlStr && setEmailBody(e ? htmlStr : "");
    setEmailData({
      ...emailData,
      subject: e ? subjectStr : "",
      html: e ? htmlStr : htmlStr,
    });
  };

  // Method to handle email data
  const handleChangeEmailData = (value, key) => {
    setEmailData({ ...emailData, [key]: value });
  };

  const handleEditorChange = (content) => {
    setEmailBody(content);
    setEmailData({ ...emailData, html: content });
  };

  // Handle file upload
  const handleFileUpload = (e, key) => {
    handleClose();
    dispatch(addEmailData(e?.target?.files));
  };

  // Method to delete document locally
  const handleDeleteDocument = (index) => {
    dispatch(removeEmailDocument(index));
  };

  // HandleChange form data
  const handleChangeFormData = async (e, index, name) => {
    // If name === documentTypeId get subType and pass to slice method
    if (name === "documentTypeId") {
      const subType = await getDocumentTypeDetails(e);
      const payload = {
        e,
        index,
        name,
        subType,
      };
      dispatch(updateEmailFormData(payload));
    }
    // If name === clientId get client selected ID client details
    else if (name === "clientId") {
      const selectedClient = clientData?.filter((d) => d?.id === e);
      const payload = {
        e,
        index,
        name,
        selectedClient: selectedClient || "Both",
      };
      dispatch(updateEmailFormData(payload));
    } else {
      const payload = {
        e,
        index,
        name,
      };
      dispatch(updateEmailFormData(payload));
    }
  };

  //  Method to update email data on attachment change
  const handleFileUpdatePortalDocuments = (data) => {
    // Create array from data with and get documentTypeId, documentSubTypeId,documentTypeName,subdocumentTypeName,fileName,path,fileInfo from data?.fileName
    const tempData = [];
    data?.map((d) => {
      tempData.push({
        id: d?.id,
        path: d?.documentUrl,
        fileName: `${instructionId}_${d?.forClient}_${
          d?.subDocumentType?.name || d?.documentType?.name
        }`,
        version: d?.version,
        forClient: d?.forClient,
        documentType: d?.documentType?.name,
        documentSubType: d?.subDocumentType?.name,
      });
    });
    dispatch(addEmailPortalData(tempData));
  };

  // Method to handleClose dialog
  const handleCloseDialog = () => {
    dispatch(clearEmailDocuments());
    onClose();
  };

  const getDocumentTypeDetails = async (id) => {
    try {
      const response = await dispatch(getDocumentTypeById(id)).unwrap();
      if (response) {
        return response;
      }
    } catch (error) {
      return error;
    }
  };

  // Method to get shared email
  const getSharedEmail = async () => {
    try {
      const response = await dispatch(getManageProfileUser()).unwrap();
      if (response?.MailBoxPermissions?.length > 0) {
        setSharedEmail(response?.MailBoxPermissions);
      } else {
        setSharedEmail([]);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getSharedEmail();
    getInitialData();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        maxWidth="xl"
        fullWidth
        scroll="paper"
      >
        {isLoading && <Loader />}
        {/* Dialog to show all portal documents */}
        {isPortal && (
          <ViewPortalDocuments
            open={isPortal}
            handleClose={() => setIsPortal(false)}
            instructionId={instructionId}
            handleSave={handleFileUpdatePortalDocuments}
          />
        )}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Button
          className="save_button"
          sx={{
            position: "absolute",
            right: 60,
            top: 10,
          }}
          onClick={() => {
            const hasDuplicates = checkForDuplicateEmails();
            if (hasDuplicates) {
              dispatch(
                showSnackbar({
                  message: "Please enter unique email id in To, CC and BCC",
                  severity: "error",
                })
              );
            } else {
              handleSubmitEmail();
            }
          }}
        >
          Send
        </Button>
        <DialogTitle className="serif_display_regular_26 green">
          Send Email
          {isUploading && (
            <>
              <LinearProgress color="success" fullWidth />
              <Typography className="normal_normal_18_Manrope dark_grey">
                Uploading... {progress}
              </Typography>
            </>
          )}
        </DialogTitle>
        <DialogContent dividers={true}>
          {/* Container for subject + To + from */}
          <Grid container spacing={3}>
            {/* From section */}
            <Grid item xs={6}>
              <FormControl
                fullWidth
                size="small"
                className="normal_normal_600_12_Manropee"
              >
                <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
                  From
                </InputLabel>
                <Select
                  value={emailData?.from || ""}
                  className="textField_outlined_border"
                  label="From"
                  onChange={(e) =>
                    handleChangeEmailData(e?.target?.value, "from")
                  }
                >
                  <MenuItem value={emailData?.from}>{emailData?.from}</MenuItem>
                  {sharedEmail?.length > 0 &&
                    sharedEmail.map((d) => (
                      <MenuItem
                        value={d?.emailName}
                        className="normal_normal_medium_14_Manrope"
                      >
                        {d?.emailName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {/* Subject Section */}
              <Grid mt={2}>
                <TextField
                  className="inputTextField normal_normal_600_12_Manropee"
                  label="Subject"
                  placeholder="Enter Subject"
                  required
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={emailData?.subject || ""}
                  onChange={(e) =>
                    handleChangeEmailData(e?.target?.value, "subject")
                  }
                  error={isError && !emailData?.subject}
                  helperText={
                    isError && !emailData?.subject && VALIDATION_MSG.REQUIRED
                  }
                  // InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* To section */}
              <Grid mt={3}>
                <>
                  <AutoCompleteField
                    label="TO"
                    placeholder="Enter email"
                    className="normal_normal_600_12_Manropee"
                    defaultValue={emailData?.to || []}
                    // inputAdornment={"To"}
                    filterSelectedOptions
                    fullWidth
                    validationName="email"
                    name="to"
                    onChange={(e, newValue) =>
                      onChangeAutoComplete("to", newValue)
                    }
                    options={emailList}
                    consultantEmail={consultantEmail || []}
                    setOptions={setEmailList}
                    rules={{
                      required: {
                        value: true,
                        message: "Reciever email required",
                      },
                    }}
                  />

                  {isError && emailData?.to?.length <= 0 && (
                    <FormHelperText className="red">
                      {VALIDATION_MSG?.REQUIRED}
                    </FormHelperText>
                  )}
                  {/* Add CC / BCC toggle section */}
                  {!showBcc && (
                    <Grid
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        onClick={() => toggleBcc(true)}
                        sx={{ color: "#00CE3F", fontWeight: "bold" }}
                        my={2}
                      >
                        Add CC / BCC
                      </Button>
                      <Tooltip title="Copy link to send email" placement="top">
                        <IconButton aria-label="" onClick={copyBccLink}>
                          <CopyAll fontSize="medium" />{" "}
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  {showBcc && (
                    <>
                      {/* CC Section */}
                      <Grid mt={2}>
                        <AutoCompleteField
                          label="CC"
                          placeholder="Enter email"
                          fullWidth
                          validationName="email"
                          onChange={(e, newValue) =>
                            onChangeAutoComplete("cc", newValue)
                          }
                          options={emailList}
                          consultantEmail={[]}
                          setOptions={setEmailList}
                        />
                      </Grid>

                      {/* BCC Section */}
                      <Grid mt={2}>
                        <AutoCompleteField
                          label="BCC"
                          placeholder="Enter email"
                          fullWidth
                          validationName="email"
                          onChange={(e, newValue) =>
                            onChangeAutoComplete("bcc", newValue)
                          }
                          options={emailList}
                          consultantEmail={[]}
                          setOptions={setEmailList}
                        />
                      </Grid>
                    </>
                  )}
                </>
              </Grid>
            </Grid>

            {/* Email Template section */}
            <Grid item xs={6}>
              <FormControl
                fullWidth
                size="small"
                className="normal_normal_600_12_Manropee"
              >
                <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
                  Templates
                </InputLabel>
                <Select
                  className="textField_outlined_border"
                  label="Email Templates"
                  onChange={(e) => handleChangeEmailTemplate(e?.target?.value)}
                >
                  <MenuItem>--</MenuItem>
                  {emailTemplates?.length > 0 &&
                    emailTemplates.map((d) => (
                      <MenuItem
                        key={d?.id}
                        value={d?.id}
                        className="normal_normal_medium_14_Manrope"
                      >
                        {d?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Divider style={{ marginTop: "2rem", backgroundColor: "green" }} />
          {/* Add file section */}
          <Grid
            style={{
              width: 150,
              padding: 3,
              position: "relative",
              left: "52%",
              top: 20,
              zIndex: 111,
            }}
          >
            {/* Button to upload attatchment */}
            <FileUploadOutlinedIcon
              style={{ fontSize: "28px", cursor: "pointer" }}
              aria-controls={openMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              onClick={handleClick}
              className="green"
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {/* Menu Item to open documents from local system */}
              <MenuItem style={{ padding: 0 }}>
                <Button
                  style={{
                    backgroundColor: "#E5FAEB",
                    cursor: "pointer",
                    boxShadow: "none",
                  }}
                  className="green"
                  component="label"
                  variant="standard"
                  startIcon={
                    <FileUploadOutlinedIcon style={{ fontSize: "20px" }} />
                  }
                  type="file"
                  onChange={(event) => handleFileUpload(event)}
                >
                  Local <VisuallyHiddenInput type="file" multiple />
                </Button>
              </MenuItem>
              <Divider />
              {/* Menu Item to open dialog for portal documents */}
              <MenuItem
                style={{ padding: 0 }}
                onClick={() => {
                  setIsPortal(true);
                  handleClose();
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#E5FAEB",
                    cursor: "pointer",
                    boxShadow: "none",
                  }}
                  className="green"
                  component="label"
                  variant="standard"
                  startIcon={<LanguageIcon style={{ fontSize: "20px" }} />}
                  type="file"
                >
                  Portal
                </Button>
              </MenuItem>
            </Menu>
          </Grid>
          {/* Conatiner for email body and attatchment */}
          <Grid
            container
            spacing={3}
            mt={1}
            style={{ position: "relative", bottom: 50 }}
          >
            {/* Email Editor joEditor */}
            <Grid item xs={7}>
              <JoditEditotRichText
                value={emailData?.html || ""}
                onChange={handleEditorChange}
                filesHandler={setAttachments}
                files={attachments}
              />
            </Grid>
            {/* Email Attatchment section */}
            <Grid item xs={5}>
              {/* Document Type sub type and client and delete section */}
              {attatchments?.length > 0 && (
                <Grid>
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer
                      style={{
                        maxWidth: "100%",
                        cursor: "pointer",
                      }}
                      sx={{ maxHeight: 550 }}
                      className="instruction_dashboard_scrollbar"
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky-table a dense table"
                        size="small"
                      >
                        <TableHead>
                          {/* Columns section */}
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {attatchments?.map((d, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {d?.id ? (
                                  <Typography>
                                    {d?.forClient || "NA"}
                                  </Typography>
                                ) : (
                                  <FormControl fullWidth>
                                    <Select
                                      className="formControlField"
                                      value={d?.clientId}
                                      variant="standard"
                                      size="small"
                                      onChange={(event) =>
                                        handleChangeFormData(
                                          event?.target?.value,
                                          index,
                                          "clientId"
                                        )
                                      }
                                    >
                                      {clientData?.map((data) => (
                                        <MenuItem
                                          key={data?.id}
                                          value={data?.id}
                                          disabled={d?.id}
                                        >
                                          {`${data?.firstName} ${data?.lastName}`}
                                        </MenuItem>
                                      ))}
                                      <MenuItem
                                        value={"Both"}
                                        disabled={
                                          clientData?.length < 2 || d?.id
                                        }
                                      >
                                        Both
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                )}
                              </TableCell>

                              <TableCell>
                                {d?.id ? (
                                  <Typography>
                                    {d?.documentType || "NA"}
                                  </Typography>
                                ) : (
                                  <FormControl fullWidth>
                                    <Select
                                      className="formControlField"
                                      value={d?.documentTypeId}
                                      variant="standard"
                                      size="small"
                                      onChange={(event) =>
                                        handleChangeFormData(
                                          event?.target?.value,
                                          index,
                                          "documentTypeId"
                                        )
                                      }
                                    >
                                      {documentType?.map((data) => (
                                        <MenuItem
                                          key={data?.id}
                                          value={data?.id}
                                          disabled={d?.id}
                                        >
                                          {data?.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                )}
                              </TableCell>

                              <TableCell>
                                {d?.id ? (
                                  <Typography>
                                    {d?.documentSubType || "NA"}
                                  </Typography>
                                ) : (
                                  <FormControl fullWidth>
                                    <Select
                                      className="formControlField"
                                      value={d?.documentSubTypeId}
                                      variant="standard"
                                      size="small"
                                      onChange={(event) =>
                                        handleChangeFormData(
                                          event?.target?.value,
                                          index,
                                          "documentSubTypeId"
                                        )
                                      }
                                    >
                                      {d?.documentSubType?.map((data) => (
                                        <MenuItem
                                          key={data?.id}
                                          value={data?.id}
                                          disabled={d?.id}
                                        >
                                          {data?.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                )}
                              </TableCell>

                              <TableCell>
                                {d?.id
                                  ? d?.fileName
                                  : d?.fileName?.name || "NA"}
                              </TableCell>

                              <TableCell>
                                <IconButton
                                  onClick={() => handleDeleteDocument(index)}
                                >
                                  <DeleteIcon style={{ color: "#ff0000" }} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EmailBox;
